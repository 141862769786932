<template>
    <div class="acountNav">
        <div class="userinfo">
            <img :src="userInfo.avatar">
            <!-- <img v-else src="../../static/icon/avatar.png"> -->
            <div class="user-name" :class="userInfo.isMember?'user-name-active':''">
                <span class="user-name-name">{{ userInfo.nickName || '用户名' }}</span>
                <span class="user-name-subscription">{{ userInfo.isMember?userInfo.member.subscriberType===2?'周订阅':userInfo.member.subscriberType===4?'月订阅':userInfo.member.subscriberType===8?'季度订阅':'年度订阅':'暂无订阅' }}</span>
            </div>
            <span class="user-expiryDate" v-if="userInfo.isMember">{{ userInfo.member.expiryDate.split('T')[0] }}到期</span>
            <div class="btn" @click="goAcount">编辑资料</div>
        </div>
        <div class="nav">
            <div class="nav-item" :class="navIdx === index ? 'nav-item-active' : ''" v-for="(item, index) in navLists"
                :key="index" @click="goNav(index)">{{ item.title }}</div>
        </div>
    </div>
</template>

<script>
import { getUserInfo } from '@/api/login.js'
export default {
    name: "acountNav",
    data() {
        return {
            navLists: [
                { title: '我的收藏', path: '/collection' },
                { title: '我的关注', path: '/follow' },
                { title: '我的已购', path: '/buyed' },
                { title: '我的订单', path: '/order' },
                { title: '消息提醒', path: '/notice' },
                { title: '账号与安全', path: '/acount' },
                { title: '常见问题', path: '/problem' },
            ],
            navIdx: null,
            userInfo: {},//用户信息
        }
    },
    created() {
        this.navLists.map((el, index) => {
            if (el.path === this.$route.fullPath) {
                this.navIdx = index
            }
        })
        if (this.navIdx === null) {
            this.navIdx = 0
        }
        this.getUserInfo()
    },
    methods: {
        /**
         * 获取个人信息
         */
        getUserInfo() {
            getUserInfo().then(userInfo => {
                console.log(userInfo)
                this.userInfo = userInfo
            })
        },
        /**
         * 切换菜单
         */
        goNav(index) {
            this.$router.push({ path: this.navLists[index].path });
            this.navIdx = index
        },
        /**
         * 编辑资料
         */
        goAcount() {
            this.$router.push({ path: '/acount' });
        }
    }
}
</script>

<style scoped>
.acountNav {
    width: 296px;
    display: flex;
    flex-direction: column;
}

.userinfo {
    width: 296px;
    height: 286px;
    margin-top: 16px;
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.userinfo img {
    width: 106px;
    height: 106px;
    border-radius: 106px;
}

.userinfo .user-name {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 26px;
}

.userinfo .user-name .user-name-name {
    color: #06121E;
    font-size: 18px;
}

.userinfo .user-name .user-name-subscription {
    width: 64px;
    height: 22px;
    background: #ECECEC;
    border-radius: 2px 2px 2px 2px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #6D6D6D;
    font-size: 14px;
    margin-left: 6px;
}

.userinfo .user-name-active .user-name-name {
    color: #4181B8;
    font-weight: bold;
}

.userinfo .user-name-active .user-name-subscription {
    background: #EAF1F7;
    color: #4181B8;
}

.userinfo .user-name-active {
    color: #4181B8;
    font-size: 18px;
}

.userinfo .user-expiryDate {
    color: #999999;
    font-size: 14px;
    margin-top: 4px;
}

.userinfo .btn {
    background-color: #DD0722;
    margin-top: 14px;
    width: 84px;
    height: 30px;
    border-radius: 44px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ffffff;
    font-size: 14px;
    cursor: pointer;
}

.nav {
    background-color: #ffffff;
    width: 296px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 16px;
    padding: 0 20px;
}

.nav-item {
    width: 256px;
    height: 58px;
    display: flex;
    align-items: center;
    color: #06121E;
    font-size: 14px;
    border-bottom: 1px solid #E8E8E8;
    cursor: pointer;
}

.nav-item-active {
    position: relative;
}

.nav-item-active::after {
    content: '';
    width: 2px;
    height: 58px;
    background-color: #DD0722;
    position: absolute;
    left: -20px;
    top: 0px;
}

.nav-item:last-child {
    border-bottom: 0px;
}</style>
