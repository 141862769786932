<template>
    <div class="pages">
        <div class="footer-main">
            <span>版权所有©财讯平台 青峰见网络科技（苏州）有限公司 </span>
            <img src="../../static/icon/beian.png">
            <span>苏公网安备32059002004804号</span>
            <span>苏ICP备2021012769号</span>
        </div>
    </div>
</template>

<script>
export default {
    name: "pageFooter"
}
</script>

<style scoped>
.pages {
    width: 100%;
    height: 52px;
    background: #2A1F1F;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
}

.footer-main {
    width: 1200px;
    height: 52px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.footer-main img{
    width: 20px;
}
.footer-main span {
    margin: 0 12px;
    color: #ffffff;
    font-size: 12px;
}
</style>
