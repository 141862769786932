<template>
    <div class="get-more-box">
        <span v-if="text === 'getMore'" @click="getMore">点击加载更多</span>
        <span v-if="text === 'loading'">加载中。。。</span>
        <span v-if="text === 'noMore'">没有更多数据了</span>
    </div>
</template>

<script>
export default {
    name: "getMore",
    props: {
        state: {
            type: String
        }
    },
    data() {
        return {
            text: 'getMore'
        }
    },
    watch: {
        state(e) {
            this.text = e
        }
    },
    methods: {
        /**
         * 加载更多
         */
        getMore() {
            this.$emit('getMore')
        }
    }
}
</script>

<style scoped>
.get-more-box {
    width: 100%;
    display: block;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.get-more-box span{
    cursor: pointer;
    width: 160px;
    height: 40px;
    border-radius: 50px;
    border: 1px solid #9ba4b1;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    color: #9ba4b1;
}
</style>
