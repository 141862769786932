<template>
    <div class="pages">
        <div class="main">
            <div class="left">
                <div class="logo" @click="goHome">
                    <img src="../../static/icon/logo.png">
                    <span>财讯</span>
                </div>
                <div class="nav">
                    <span class="nav-item" :class="navIdx === index ? 'nav-item-active' : ''"
                        v-for="(item, index) in navLists" :key="index" @click="changeNav(index)">{{ item.name }}</span>
                </div>
            </div>
            <div class="right" @mouseleave="outBtnIsShow = false">
                <div class="search">
                    <img src="../../static/icon/search-icon.png">
                    <input v-model="searchKey" type="text" placeholder="请输入搜索词">
                    <span @click="search">搜索</span>
                </div>
                <img @click="goNotice" class="notice-icon" src="../../static/icon/notice-icon.png">
                <img @mouseenter="outBtnIsShow = true" class="avatar" @click="goAcount" :src="userInfo.avatar"
                    v-if="userInfo.avatar && token">
                <span @mouseenter="outBtnIsShow = true" v-if="token" class="user-name" @click="goAcount">{{
                    userInfo.nickName || '用户名' }}</span>

                <span @mouseleave="outBtnIsShow = false" class="user-name-out" @click="goOutLogin"
                    v-if="outBtnIsShow && token">退出登录</span>
                <span v-if="!token" class="user-name" @click="goLogin">去登陆</span>
            </div>
        </div>
        <div class="go-head" @click="scrollToTop">
            <img src="../../static/icon/go-head.png">
            <span>顶部</span>
        </div>
    </div>
</template>

<script>
import { getUserInfo } from '@/api/login.js'
import { getToken, removeToken } from "@/utils/auth";
export default {
    name: "pageHeader",
    data() {
        return {
            navLists: [{
                name: '首页',
                url: '/home'
            },
            {
                name: '投资学院',
                url: '/college'
            },
            {
                name: '订阅',
                url: '/subscription'
            }],
            navIdx: null,
            userInfo: {},//用户信息
            token: getToken(),
            outBtnIsShow: false,//退出登录按钮是否显示
            searchKey: '',//搜索词
        }
    },
    created() {
        this.navLists.map((el, index) => {
            if (el.url === this.$route.path) {
                this.navIdx = index
            }
        })
        if (this.navIdx === null) {
            this.navIdx = 0
        }
        console.log(this.$route)
        if(this.$route.path === '/collegeDetails'){
            this.navIdx = 1
        }
        this.getUserInfo()
    },
    methods: {
        /**
         * 获取个人信息
         */
        getUserInfo() {
            if (!getToken()) return
            getUserInfo().then(userInfo => {
                this.userInfo = userInfo
            })
        },
        /**
         * 切换菜单
         */
        changeNav(index) {
            if (this.$route.fullPath === this.navLists[index].url) {
                this.$router.push({ path: "/refresh" });
            } else {
                this.$router.push({ path: this.navLists[index].url });
                this.navIdx = index
            }
        },
        /**
         * 前往个人中心
         */
        goAcount() {
            this.$router.push({ path: '/collection' });
        },
        /**
         * 去登陆
         */
        goLogin() {
            this.$router.push({ path: '/login' });
        },
        /**
         * 去消息通知
         */
        goNotice() {
            this.$router.push({ path: '/notice' });
        },
        /**
         * 去首页
         */
        goHome() {
            if (this.$route.fullPath === '/home') {
                this.$router.push({ path: "/refresh" });
            } else {
                this.$router.push({ path: '/' });
            }
        },
        /**
         * 退出登录
         */
        goOutLogin() {
            removeToken()
            this.$router.push({ path: "/refresh" });
        },
        /**
         * 搜索
         */
        search() {
            if (this.$route.path === '/home') {
                this.$emit('search', this.searchKey)
            } else {
                this.$router.push({ path: '/', query: { title: this.searchKey } });
            }
        },
        scrollToTop() {
            window.scrollTo({
                top: 0,
                behavior: 'smooth' // 平滑滚动
            });
        }
    }
}
</script>

<style scoped>
.pages {
    width: 100%;
    height: 68px;
    box-shadow: 0px 1px 4px 1px rgba(0, 21, 41, 0.12);
    display: flex;
    align-items: center;
    justify-content: center;
}

.main {
    width: 1200px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.left {
    display: flex;
    align-items: center;
}

.left .logo {
    display: flex;
    align-items: center;
    cursor: pointer;
}

.left .logo img {
    width: 40px;
    height: 40px;
}

.left .logo span {
    font-size: 20px;
    color: #646464;
    margin-left: 8px;
}

.left .nav {
    height: 68px;
    display: flex;
    align-items: center;
    margin-left: 12px;
}

.left .nav .nav-item {
    margin-left: 60px;
    height: 68px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #333333;
    font-size: 16px;
    cursor: pointer;
}

.left .nav .nav-item-active {
    color: #DD0722;
    position: relative;
}

.left .nav .nav-item-active::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 4px;
    background-color: #DD0722;
    bottom: 0;
    left: 0;
}

.right {
    display: flex;
    align-items: center;
    position: relative;
}

.right .search {
    width: 388px;
    height: 34px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    border: 1px solid #999999;
    border-radius: 32px;
}

.right .search img {
    width: 14px;
    height: 14px;
    margin: 0 14px;
}

.right .search input {
    width: 280px;
    border: 0;
    border-right: 1px solid #999999;
}

.right .search span {
    color: #999999;
    font-size: 14px;
    margin: 0 16px;
    cursor: pointer;
}

.right .notice-icon {
    margin-left: 24px;
    width: 16px;
    height: 16px;
    cursor: pointer;
}

.right .avatar {
    width: 24px;
    height: 24px;
    border-radius: 24px;
    margin-left: 24px;
    cursor: pointer;
}

.right .user-name {
    margin-left: 8px;
    color: #333333;
    font-size: 14px;
    cursor: pointer;
}

.right .user-name-out {
    color: #333333;
    font-size: 14px;
    cursor: pointer;
    position: absolute;
    right: -20px;
    width: 100px;
    height: 40px;
    background-color: azure;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 34px;
}

.go-head {
    position: fixed;
    bottom: 140px;
    right: 290px;
    width: 50px;
    height: 60px;
    background: #FFFFFF;
    border-radius: 5px 5px 5px 5px;
    border: 1px solid #E2E2E2;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.go-head img {
    width: 12px;
}

.go-head span {
    color: #4A4A4A;
    font-size: 14px;
    margin-top: 6px;
}
</style>
